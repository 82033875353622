<template>
    <div class="card" style="min-height: 200px">
        <vue3-html2pdf
            :show-layout="true"
            :float-layout="false"
            :enable-download="true"
            :preview-modal="false"
            :paginate-elements-by-height="1400"
            pdf-content-width="100%"
            :htmlToPdfOptions="htmlToPdfOptions"

            ref="html2Pdf"
        >
            <template  v-slot:pdf-content>
                <div class="card-body">
                    <div class="p-1">
                        <img :src="company.logo64" :alt="company.name" class="img-fluid" style="max-height: 100px;"/>
                    </div>

                    <div class="col-12 px-2">
                        <p class="h1 m-0 text-center">
                            Money Receipt
                        </p>
                        <hr>
                    </div>

                    <div class="row p-2">
                        <div class="col-12 col-lg-6">
                            <p class="h4">Receipt from</p>
                            <p v-if="moneyReceipt.contact">{{moneyReceipt.contact.name}}</p>
                        </div>
                        <div class="col-12 col-lg-6 text-right">
                            <p><strong>Date: </strong> {{moneyReceipt.receipt_date}}</p>
                            <p><strong>Ref: </strong> {{moneyReceipt.voucher_no}}</p>
                        </div>
                        <div class="col-12">
                            <p>
                                <strong>Amount: </strong>
                                {{ formatCurrency(moneyReceipt.total_paid_amount) ?? '' }}
                            </p>
                            <p>
                                <strong>Amount In Words: </strong>
                                {{ (moneyReceipt.total_paid_amount) ? currencyToWord(moneyReceipt.total_paid_amount) : '' }}
                            </p>
                            <p>
                                <strong>Payment Method: </strong>
                                {{ (moneyReceipt.account_head) ? moneyReceipt.account_head.name : '' }}
                            </p>
                            <p>
                                <strong>Cheque Details: </strong>
                                {{ moneyReceipt.receipt_reference }}, {{ moneyReceipt.receipt_reference_date }}
                            </p>
                            <p>
                                <strong>Invoice details: </strong>
                            </p>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Invoice No</th>
                                        <th>Invoice Date</th>
                                        <th class="text-right">Amount Paid</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, i) in moneyReceipt.general">
                                        <td>{{ item.bill_number }}</td>
                                        <td>{{ item.date }}</td>
                                        <td class="text-right">{{ formatCurrency(item.payment) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p class="mt-2">
                                <strong>Thanking you: </strong> <br>
                                {{company.name}}
                            </p>
                            <p>
                                <strong>Authorization: </strong>
                                N/A
                            </p>
                            <p v-if="moneyReceipt.user">
                                <strong>Receipt by: </strong> <br>
                                {{moneyReceipt.user.name}}
                            </p>
                        </div>

                        <hr class="mt-2">

                        <div class="row" v-if="company.name">
                            <div class="col-12 text-center">
                                <p>
                                    {{company.name}}
                                    Address: {{company.address}},
                                    Phone: {{company.phone}}
                                    E-mail: {{company.email}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </vue3-html2pdf>
        <div class="card-footer">
            <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script>
import handleCompany from "@/services/modules/company";
import handlePurchase from "@/services/modules/purchase";
import Loader from "@/components/atom/LoaderComponent";
import {onMounted, ref, inject, computed} from "vue";
import {useRoute} from "vue-router";
import Vue3Html2pdf from "vue3-html2pdf";

export default {
    name: "Print",
    components: {
        Loader,
        Vue3Html2pdf
    },

    setup() {
        const company = ref({});
        const loader = ref(false);
        const moneyReceipt = ref({});
        const html2Pdf = ref(null);
        const {fetchCompanyInfo} = handleCompany();
        const {fetchMoneyReceipt, formatCurrency, currencyToWord} = handlePurchase();
        const route = useRoute();
        const showError =  inject('showError');

        const voucher_no = computed(() => {
            return moneyReceipt.value.voucher_no;
        });

        const htmlToPdfOptions = ref({
            margin: 0,
            filename: voucher_no,
            image: {
                type   : 'jpeg',
                quality: 1
            },

            enableLinks: false,

            html2canvas: {
                scale  : 2,
                useCORS: true
            },

            jsPDF: {
                unit       : 'mm',
                format     : 'a3',
                orientation: 'portrait'
            }
        });

        const exportToPDF = () => {
            html2Pdf.value.generatePdf();
        }

        onMounted(() => {
            loader.value = true;
            const companyQuery = `?company_id=${route.params.companyId}`;
            const fetchCompany = fetchCompanyInfo(route.params.companyId);
            const fetchSingleMR = fetchMoneyReceipt(route.params.receiptId, companyQuery);
            Promise.all([
                fetchCompany.then(res => {
                    company.value = res.data;
                }),
                fetchSingleMR.then(res => {
                    moneyReceipt.value = res.data;
                })
            ])
                .then( () => loader.value = false )
                .catch( (err) => {
                    showError("Something went wrong");
                    loader.value = false;
                } )

        });
        return {
            company,
            loader,
            moneyReceipt,
            html2Pdf,
            htmlToPdfOptions,
            formatCurrency,
            currencyToWord,
            exportToPDF,
        }
    }
}
</script>